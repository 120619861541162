type WrappedResponse<T> = {
  data?: {
    data?: T;
  };
};

export default function unwrapData<T>(
  response: WrappedResponse<T> | undefined,
  defaultValue: T | null = null,
): T | null {
  return response?.data?.data ?? defaultValue;
}
